/**
 * @name: index
 * @author: Gzm
 * @date: 2023-06-01 15:56
 * @description：专题活动
 * @update: 2023-06-01 15:56
 */
import {get, postJ} from "@/request";
import {IWalletRules, IWalletRulesList} from "@/apis/marketingManage/rules/types";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import {IPage, IPageRes} from "@/apis/page";
import {IThematicQuery} from "@/apis/marketingManage/thematicActivities/types";
import {ICourse} from "@/apis/coursesManage/courses/types";


/**
 * 创建专题活动
 */
export const thematicActivitiesCreateApi = (param:IThematicQuery)=> postJ("/golf/thematicActivities/create", param)

/**
 * 查询赛事列表
 */
export const queryContestListApi = ()=> get("/golf/contest/queryContestList", )

/**
 * 查询赛事列表
 */
export const queryThematicActivitiesQueryContestListListApi = (param:any)=> get("/golf/thematicActivities/queryContestList",param )

/**
 * 钱包充值规则分页查询
 */
export const queryThematicActivitiesListApi = ()=> get("/golf/thematicActivities/queryThematicActivitiesList")

/**
 * 上下架
 */
export const upAndDownShelfApi = (param:any)=> postJ("/golf/thematicActivities/upAndDownShelf",param)

/**
 * 批量删除
 */
export const thematicActivitiesBatchDeleteApi = (ids:string)=> get("/golf/thematicActivities/batchDelete",{ids})

/**
 * 修改
 */
export const thematicActivitiesModifyApi = (data: IThematicQuery) => postJ("/golf/thematicActivities/modify", data)

/**
 * 查询详情
 * @param id
 */
export const thematicActivitiesDetailApi = (id: string) => get<ICourse>(`/golf/thematicActivities/detail/${id}` )

